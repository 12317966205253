import Cookies from 'js-cookie'
import myAxios from 'service/axios'

// Redux store
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { changeLoading, userAuth } from 'store/store'

export default async function checkTokenValidity(dispatch: Dispatch<AnyAction>, changeLoader: (param: boolean) => void) {
  try {
    dispatch(changeLoading(true))

    const response = await myAxios.get('/users/userme')

    dispatch(
      userAuth({
        data: { ...response?.data, access_token: response?.data?.access_token || Cookies?.get('$W$E$B$F$L$A$K$O$N$.$U$Z$U$S$E$R$T$O$K$E$N$') || '' },
        type: 'LOGIN',
      }),
    )
  } catch {
    dispatch(userAuth({ data: null, type: 'LOGOUT' }))
  } finally {
    changeLoader(false)
    dispatch(changeLoading(false))
  }
}