import{lazy}from'react'
import{default as SEO}from'./seo/SEO'
const Map=lazy(()=>import('./map/Map'))
const Hero=lazy(()=>import('./hero/Hero'))
import{default as Loader}from'./loader/Loader'
const Navbar=lazy(()=>import('./navbar/Navbar'))
const Chat=lazy(()=>import('./realtimeChat/RealtimeChat'))
const IImageViewer=lazy(()=>import('./photoViewer/PhotoViewer'))
const AsyncSelect=lazy(()=>import('./asyncSelect/AsyncSelect'))
const CompanyNews=lazy(()=>import('./companyNews/CompanyNews'))
const ContactForm=lazy(()=>import('./contactForm/ContactForm'))
import{default as PopupADSModal}from'./popupADSModal/PopupADSModal'
const PasswordInput=lazy(()=>import('./passwordInput/PasswordInput'))
const CatalogSearch=lazy(()=>import('./catalogSearch/CatalogSearch'))
const SocialLinkFixed=lazy(()=>import('./socialLinkFixed/SocialLinkFixed'))
import{default as SectionVisibilityDetector}from'./sectionVisibilityDetector/SectionVisibilityDetector'
export{SEO,Map,Hero,Loader,Navbar,AsyncSelect,CompanyNews,ContactForm,PasswordInput,CatalogSearch,PopupADSModal,Chat,IImageViewer,SectionVisibilityDetector,SocialLinkFixed}