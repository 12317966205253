export default {
  title: 'Результаты поиска',
  heading: 'Результаты поиска',
  resultsNotFound: 'Нет результатов по выбранным параметрам!',
  vendor: 'Артикул',
  standard: 'Стандарт горла',
  height: 'Высота',
  diameter: 'Диаметр',
  volume: 'Объём',
  material: 'Материал',
  category: 'Категория',
  color: 'Цвет',
  price: 'Цена',
  gram: 'Грамм',
}